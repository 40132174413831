/// <reference path="o365.pwa.declaration.shared.dexie.objectStores.UserDevice.d.ts" />

import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';

import type * as UserDeviceModule from 'o365.pwa.declaration.shared.dexie.objectStores.UserDevice.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

(() => {
    const indexedDBHandlerImportmapEntry = self.o365.getImportMapEntryFromImportUrl('o365.pwa.modules.sw.IndexedDBHandler.ts');

    class UserDevice implements UserDeviceModule.UserDevice {
        static objectStoreDexieSchema: string = "&deviceRef";

        deviceRef: string;
        deviceInfoString: string;

        constructor(options: UserDeviceModule.IUserDeviceOptions) {
            this.deviceRef = options.deviceRef ?? self.crypto.randomUUID();
            this.deviceInfoString = options.deviceInfoString;
        }

        public async save(): Promise<void> {
            const { IndexedDBHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.shared.IndexedDBHandler.d.ts')>("o365.pwa.modules.sw.IndexedDBHandler.ts", indexedDBHandlerImportmapEntry);

            await IndexedDBHandler.updateUserDevice(this);
        }

        public async delete(): Promise<void> {
            const { IndexedDBHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.shared.IndexedDBHandler.d.ts')>("o365.pwa.modules.sw.IndexedDBHandler.ts", indexedDBHandlerImportmapEntry);
            
            await IndexedDBHandler.deleteUserDevice(this);
        }
    }

    self.o365.exportScripts({ UserDevice });
})();
